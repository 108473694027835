<template>
    <div>
        <DeletionPractise />
    </div>
</template>

<script>
import DeletionPractise from '@/components/settings/deletionPractise/index.vue';

export default {
    name: 'SettingsDeletionPractise',
    components: {
        DeletionPractise,
    },
};
</script>
